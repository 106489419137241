.tf-section.top-seller.home5 .author-avatar {
    margin-right: 12px !important;
    margin-left: 7px;
} 

.tf-section.top-seller.home5 .author-avatar ion-img {
    width: 100px !important;
    height: 100px !important;
} 

.tf-section.top-seller.home5 .sc-author-box.style-3 {
    padding: 9px 16px 9px 6px;
    border: 1px solid rgb(234 234 234);
    position: relative;
    height: 145px;
    
    transform: translateZ(0);
    transition: box-shadow .15s ease-in-out,transform .15s ease-in-out;
    will-change: box-shadow;
    cursor: pointer;
    backface-visibility: hidden;
}


.tf-section.top-seller.home5 .sc-card-product {
    border: 1px solid rgb(234 234 234);
}

.tf-section.top-seller.home5 .sc-card-product .card-media {
    margin-bottom: 5px !important;
}

.tf-section.top-seller.home5 .sc-card-product .info span {
    color: var(--primary-color9);
    font-size: 16px;
    line-height: 21px;
}

.sc-card-product .card-title2 h5 {
    /* line-height: 20px; */
    margin-bottom: 5px;
}

.tf-section.top-seller.home5 .sc-card-product .card-title2 .price h5 {
    font-size: 23px;
    margin: 0;
}

.tf-section.top-seller.home5 .sc-card-product .card-title2 {
    margin-bottom: 10px;
    padding: 0 10px;
    text-align: center;
    background: rgb(255 255 255);
    position: relative;

}



.tf-section.top-seller.home5 .sc-author-box.style-3 ion-chip {
    padding: 0 10px;
}
/* 
.tf-section.top-seller.home5 .sc-author-box.style-3:hover {
    box-shadow: 0 2px 8px 0 rgb(99 99 99 / 20%);
    transform: translateZ(0) scale(1.05);
    background: rgb(255 255 255);
} */

.tf-section.top-seller.home5 .sc-author-box.style-3 .author-infor h5 {
    font-weight: 700;
    line-height: 20px;
    margin-bottom: 1px;

    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;  
    overflow: hidden;
}

.tf-section.top-seller.home5 .sc-author-box.style-3 .author-infor .tag {
    margin-bottom: 10px;
}





.tf-section.top-seller.home5 .sc-author-box.style-3 .author-infor .price {
    font-size: 19px;
    font-weight: 700 !important;
}

.my-masonry-grid {
    display: -webkit-box;
    /* Not needed if autoprefixing */
    display: -ms-flexbox;
    /* Not needed if autoprefixing */
    display: flex;
    /* margin-left: -30px; */
    /* gutter size offset */
    /* width: auto;
    padding: 0 10px; */
}

.my-masonry-grid_column {
    padding-left: 15px;
    padding-right: 15px;
    /* gutter size */
    background-clip: padding-box;
}


.sd-fader {
    pointer-events: none;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100px;
    background: rgb(255 255 255);
    z-index: 1;
    background: linear-gradient(0deg, rgb(33 33 33) 0%, rgb(0 0 0 / 0%) 100%);
}

.sc-card-product:hover {
    transform: translateY(0px) !important;
}