#products-page-controller {
 

    .sc-card-product .card-title2 {
        margin-bottom: 10px;
        // padding: 0 10px;
        text-align: center;
        background: rgb(255, 255, 255);
        position: relative;
    }

    // .MuiTreeItem-content {
    //     padding-top: 3px;
    //     padding-bottom: 3px;
    // }

    .MuiTreeItem-content.Mui-selected .MuiTreeItem-label {
        font-weight: 700;
    }
    

}

.uploadFile input[type=file]:before {
    content: 'Prześlij plik' !important;
}