$namespace: "cart";

.cart {

	background: white;
	position: absolute;
	bottom: 34px;
	right: 48px;
	width: 410px;

}

.#{$namespace}-img {
    width: 50px;
	border-radius: 10px;
}

.#{$namespace}-item {
	padding: 7px 0;
	cursor: pointer;
}

.#{$namespace}-item,
.#{$namespace}-item ion-item {
	--background: transparent;
}

.#{$namespace}-item ion-item {
	// --color: white;
}

// .#{$namespace}-item:hover {
// 	background: rgba(0, 0, 0, 0.7);
// 	--color: white;
// }

.#{$namespace}-item:hover ion-item {
	// --color: white;
}

.#{$namespace}-item ion-label ion-item {
	--min-height: 25px;
	--inner-padding-end: 0;
}

.#{$namespace}-item .desc-header ion-label {
	font-weight: 900;
	white-space: unset;
	margin: 0;
}

.#{$namespace}-item ion-label {
	white-space: unset;
	margin: 0;
}

.#{$namespace}-item .cart-remove {
	opacity: 0;
}

.#{$namespace}-item:hover .cart-remove {
	opacity: 1;
}

.#{$namespace}-counter {
	width: 35px !important;
    height: 35px !important;
    position: absolute !important;
    top: -9px !important;
    right: -9px !important;
    padding: 0 !important;
    margin: 0 !important;
}