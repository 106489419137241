.activateOnScroll {
    opacity: 0;
    transition: .4s opacity,.8s transform cubic-bezier(.07,.89,.79,.95),.8s;
    transform: translateY(48px);
}

@for $i from 0 through 50 {
    .td-#{$i} {
        transition-delay: ($i * 100) + ms;
    }
}

.flat-title-page.home5 .swiper {
    height: 800px;
    width: 250px;
    padding: 0 10px;
}

.flat-title-page.home5 .swiper .swiper-wrapper {
    height:auto !important;
}

.flat-title-page.home5 .swiper .swiper-slide {
    height:auto !important;
}

.flat-title-page.home5 .swiper img {
    width:100%;
    height:auto;
    border-radius: 15px;
}

.sc-card-product {
    padding: 5px !important;
}

.home-7 .sc-card-product {
    margin-bottom: 20px !important;
}

.sc-card-product {
    padding-bottom: 10px !important;
}

.themesflat-container {
    padding-left: 0 !important;
    padding-right: 0 !important;
}


.main-nav>ul>li {
    padding: 22px 12px 22px 12px !important;
}

.header_1 {
    height: 70px !important;
}

#footer {
    padding: 35px 0 30px !important;
}