.product-price {
    text-align: right;
    /* border-top: 1px solid rgb(223 223 223); */
    padding-top: 20px;
    font-size: 33px;
}

.product-price-description {
    font-size: 15px;
    font-weight: 800;
    margin-top: 5px;
    color: var(--ion-color-primary);
}

.product-custom-description {
    border-bottom: 1px solid rgb(223 223 223);
    padding-bottom: 20px;
}

.text-center-xy {
    text-align: center;
    margin: auto;
}

.product-modal .product-img {
    max-height: 550px;
}